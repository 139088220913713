
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { getUserAvatar, avatarStatus, avatarSwitch } from "@/api/request/supervise";

//组件
@Component({
  name: "Avatar",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private switchStatus: number = 0;
  private listLoading: boolean = true;
  private switchLoading: boolean = false;

  //请求数据
  private listQuery: any = {
    //页码数据
    page: 1,
    row: 20,

    //请求数据
    userid: undefined,
  };

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);

    //获取数据
    const { data } = await getUserAvatar(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;
    this.switchStatus = data.switch;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理重置
  private handleReset(): void {
    //数据赋值
    this.listQuery.page = 1;
    this.listQuery.userid = undefined;

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理修改
  private async switchChange(val: any) {
    //显示等待
    this.switchLoading = true;

    //处理修改
    const res = await avatarSwitch({ switch: val });

    //修改修改
    if (res.status === 0) {
      //显示提示
      this.$message.success("修改审核状态成功");

      //隐藏等待
      this.switchLoading = false;

      //获取列表
      this.getList();
    }
  }

  //状态处理
  private statusFormat(row: any): string {
    if (row.status === 1) return "待审核";
    if (row.status === 2) return "通过";
    if (row.status === 3) return "拒绝";
    if (row.status === 4) return "删除";
    return "";
  }

  //修改状态该
  private changeStatus(id: any, type: any): void {
    //数据赋值
    let text: any;
    if (type === 2) text = "通过";
    if (type === 3) text = "拒绝";
    if (type === 4) text = "删除";

    //显示提示
    this.$confirm(`确定要${text}？`, "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求修改
        const res = await avatarStatus({ id, status: type });

        //修改成功
        if (res.status === 0) {
          //显示提示
          this.$message.success(`${text}成功`);

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
